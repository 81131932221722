import { useEffect, useState } from 'react';

import { ProtectRoute } from '../contexts/auth';
import { useAuth } from '../contexts/auth';
import { getUserRole, userRoles } from '../utils/userHelper';

import { useRouter } from 'next/router';

const Home = () => {
  const { user } = useAuth();
  const router = useRouter();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user) {
      if (getUserRole(user) === userRoles.OWNER && user.accommodationsOwner && user.accommodationsOwner.length > 0) {
        router.push(`/owner/accommodation/${user.accommodationsOwner[0].id}`);
      } else {
        if (getUserRole(user) === userRoles.TENANT && user.accommodationsTenant && user.accommodationsTenant.length > 0) {
          router.push(`/tenant/accommodation/${user.accommodationsTenant[0].id}`);
        } else {
          setError(true);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <ProtectRoute>
      <>{error && <p>{`Une erreur est survenue. Merci de nous contacter à l'adresse hello@owwner.com`}</p>}</>
    </ProtectRoute>
  );
};

export default Home;
